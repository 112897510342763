import { axios, AxiosResponse } from "@sdk/axios";
import { SDKConfig } from "@sdk/default-sdk-config";
import { EntityServices } from "@sdk/utils/entity.service";
import { UserTracker } from "user-tracker";
import { iTicket, iTicketComment, iTicketTask } from "./tickets-model";

export class TicketsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {
    this.tickets.configure(config);
  }

  tickets = Object.assign(
    new EntityServices<iTicket>(SDKConfig, "tickets", {
      onCreate: () => {
        UserTracker.track("TICKETS - Create", {});
      },
      onDelete: () => {
        UserTracker.track("TICKETS - Delete", {});
      },
    }),
    {
      assignUser: async (ticketId: string, userId: string) => {
        UserTracker.track("TICKETS - Assign User", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tickets/${ticketId}/assign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      unassignUser: async (ticketId: string, userId: string) => {
        UserTracker.track("TICKETS - UnAssign User", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tickets/${ticketId}/unassign`,
          {
            userId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      subscribeContact: async (ticketId: string, contactId: string) => {
        UserTracker.track("TICKETS - Subscribe Contact", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tickets/${ticketId}/subscribe-contact`,
          {
            contactId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      unsubscribeContact: async (ticketId: string, contactId: string) => {
        UserTracker.track("TICKETS - Subscribe Contact", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tickets/${ticketId}/unsubscribe-contact`,
          {
            contactId,
          },
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addComment: async (
        ticketId: string,
        req: {
          text: string;
          attachments: string[];
          isPrivate?: boolean;
        },
      ) => {
        UserTracker.track("TICKETS - Add Comment", {});
        const results: AxiosResponse<iTicket> = await axios.post(
          this.config.basePath + `/tickets/${ticketId}/comments`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      editComment: async (
        ticketId: string,
        commentId: string,
        edits: Partial<iTicketComment>,
      ) => {
        UserTracker.track("TICKETS - Edit Comment", {});
        const results: AxiosResponse<any> = await axios.patch(
          this.config.basePath + `/tickets/${ticketId}/comments/${commentId}`,
          edits,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      removeComment: async (ticketId: string, commentId: string) => {
        UserTracker.track("TICKETS - Remove Comment", {});
        const results: AxiosResponse<any> = await axios.delete(
          this.config.basePath + `/tickets/${ticketId}/comments/${commentId}`,

          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      addTask: async (ticketId: string, task: Partial<iTicketTask>) => {
        UserTracker.track("TICKETS - Add Task", {});
        const results: AxiosResponse<any> = await axios.post(
          this.config.basePath + `/tickets/${ticketId}/tasks`,
          task,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      editTask: async (
        ticketId: string,
        taskId: string,
        edits: Partial<iTicketTask>,
      ) => {
        UserTracker.track("TICKETS - Edit Task", {});
        const results: AxiosResponse<any> = await axios.patch(
          this.config.basePath + `/tickets/${ticketId}/tasks/${taskId}`,
          edits,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      removeTask: async (ticketId: string, taskId: string) => {
        UserTracker.track("TICKETS - Remove Task", {});
        const results: AxiosResponse<any> = await axios.delete(
          this.config.basePath + `/tickets/${ticketId}/tasks/${taskId}`,

          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
        return results.data;
      },
      externalApps: {
        linkTaskToTicket: async (
          ticketId: string,
          taskId: string,
          app: "click-up",
        ) => {
          UserTracker.track("TICKETS - Link External Task", {});
          const results: AxiosResponse<any> = await axios.post(
            this.config.basePath + `/tickets/${ticketId}/link-external-task`,
            {
              app,
              taskId,
            },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
          return results.data;
        },
        unlinkTaskFromTicket: async (
          ticketId: string,
          taskId: string,
          app: "click-up",
        ) => {
          UserTracker.track("TICKETS - Unlink External Task", {});
          const results: AxiosResponse<any> = await axios.post(
            this.config.basePath + `/tickets/${ticketId}/unlink-external-task`,
            {
              app,
              taskId,
            },
            {
              headers: {
                Authorization: this.config.token,
              },
            },
          );
          return results.data;
        },
      },
    },
  );
}
