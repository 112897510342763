import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iOrganizationHelpDeskTicketType } from "@sdk/user-management/user-management.models";
import { Alert, Button, Spin, Tag, Tooltip } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import {
  LoadingIndicatorWithSpin,
  LoadingIndicatorWithoutSpin,
} from "components/common/loading-indicator/loading-indicator";
import { HelpUsHelpYou } from "components/help-us-help-you/help-us-help-you";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import {
  selectIntegrationConfig_ClickUp,
  selectTicketTypes,
} from "store/modules/workspace/workspace.selectors";
import ClickUpListSelector from "./click-up-list-selector-modal/click-up-list-selector.modal-registry";

export const ClickUpIntegrationConfig = () => {
  const integrationConfig = useSelector(selectIntegrationConfig_ClickUp);
  const isDarkMode = useSelector(selectIsDarkMode);

  const { doAction: completeIntegration, isProcessing: isAdding } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (code: string) =>
          SDK.externalAccounts.completeIntegration({
            type: "CLICK_UP",
            data: {
              code,
            },
          }),
        successMessage: "Integration has been successful",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: removeIntegration, isProcessing: isRemoving } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.externalAccounts.removeIntegration({
            type: "CLICK_UP",
          }),
        successMessage: "Integration has been removed",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: saveConfig, isProcessing: isSavingConfig } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (config) =>
          SDK.externalAccounts.updateConfig({
            type: "CLICK_UP",
            config,
          }),
        successMessage: "Configuration has been saved",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const {
    data: test,
    isLoading: isTestingIntegration,
    error,
    reload: reTestIntegration,
  } = useSDK(
    (SDK) => SDK.clickUp.getAvailableWorkspaces(),
    [integrationConfig?.config?.isReady],
    !integrationConfig?.config?.isReady,
  );

  const { triggerTempModal } = useModalPanels();

  const allTicketTypes = useSelector(selectTicketTypes);

  return (
    <>
      {integrationConfig && (
        <div>
          {integrationConfig.config.isReady ? (
            <Spin
              spinning={isTestingIntegration}
              indicator={<LoadingIndicatorWithSpin />}
            >
              {!error && (
                <Alert
                  message="Integration is active"
                  type="success"
                  showIcon
                />
              )}
              {error && (
                <Alert
                  message="Integration is not working correctly. Try Re-Authenticating"
                  type="error"
                  showIcon
                />
              )}
            </Spin>
          ) : (
            <Alert
              message="ClickUp integration is not ready yet. Complete the below steps to enable it"
              type="warning"
              showIcon
            />
          )}
          <div className="flex flex-row justify-between mt-8">
            <div className="text">Connected Workspace</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>
                  {integrationConfig.config.teamName || "Team Not Selected"}
                </Tag>
              </div>
              <Tooltip title="Remove Integration">
                <Button
                  type="link"
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => {
                    removeIntegration();
                  }}
                  loading={isRemoving}
                ></Button>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row justify-between my-2">
            <div className="text">Default List (for Chats)</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>
                  {integrationConfig.config.spaceName || "No Space Selected"}
                </Tag>
                <Tag>
                  {integrationConfig.config.folderName || "No Folder Selected"}
                </Tag>
                <Tag>
                  {integrationConfig.config.listName || "No List Selected"}
                </Tag>
              </div>
              <Tooltip title="Configure">
                <Button
                  type="link"
                  icon={<i className="ri-pencil-line"></i>}
                  onClick={() => {
                    triggerTempModal(ClickUpListSelector, {
                      initialValues: integrationConfig.config,
                      onSelected: (values) => {
                        saveConfig({
                          ...(integrationConfig?.config || {}),
                          ...values,
                          isReady: true,
                        });
                      },
                    });
                  }}
                  loading={isRemoving}
                ></Button>
              </Tooltip>
            </div>
          </div>

          <div className="font-bold">Tracker Mappings</div>
          {allTicketTypes.map((ticketType) => (
            <TrackerConfig key={ticketType.id} ticketType={ticketType} />
          ))}

          <HelpUsHelpYou
            chatMessage="How can we improve ClickUp Integration for you?"
            emoji="☝️"
            description="Share your workflows with ClickUp and we'll ensure that our ClickUp Integration saves you time."
          />
        </div>
      )}

      {!integrationConfig && (
        <div className="flex flex-row justify-center items-center">
          <Spin
            spinning={isAdding}
            indicator={<LoadingIndicatorWithoutSpin />}
            wrapperClassName="flex fill-spinner"
          >
            <ExternalAuthConnector
              type="CLICK_UP"
              onAuthenticated={({ code }) => {
                completeIntegration(code);
              }}
              label="Connect Click Up"
            />
          </Spin>
        </div>
      )}
    </>
  );
};

const TrackerConfig = ({
  ticketType,
}: {
  ticketType: iOrganizationHelpDeskTicketType;
}) => {
  const integrationConfig = useSelector(selectIntegrationConfig_ClickUp);

  const { doAction: saveConfig, isProcessing: isSavingConfig } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (config) =>
          SDK.externalAccounts.updateConfig({
            type: "CLICK_UP",
            config,
          }),
        successMessage: "Configuration has been saved",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const trackerConfig =
    integrationConfig?.config?.trackerConfigs?.[ticketType.id];

  const { triggerTempModal } = useModalPanels();

  return (
    <div className="flex flex-row justify-between my-2">
      <div className="text">
        {ticketType.emoji} {ticketType.label}
      </div>
      <div className="flex flex-row items-center">
        <div className="">
          <Tag>{trackerConfig?.listName || "No List Selected"}</Tag>
        </div>
        {trackerConfig?.listName && (
          <Tooltip title="Remove Mapping">
            <Button
              type="link"
              icon={<i className="ri-delete-bin-line"></i>}
              onClick={() => {
                saveConfig({
                  ...(integrationConfig?.config || {}),
                  trackerConfigs: {
                    ...(integrationConfig?.config?.trackerConfigs || {}),
                    [ticketType.id]: null,
                  },
                });
              }}
              loading={isSavingConfig}
            ></Button>
          </Tooltip>
        )}

        <Tooltip title="Configure">
          <Button
            type="link"
            icon={<i className="ri-pencil-line"></i>}
            onClick={() => {
              triggerTempModal(ClickUpListSelector, {
                initialValues: trackerConfig,
                onSelected: (values) => {
                  saveConfig({
                    ...(integrationConfig?.config || {}),
                    trackerConfigs: {
                      ...(integrationConfig?.config?.trackerConfigs || {}),
                      [ticketType.id]: values,
                    },
                  });
                },
              });
            }}
            loading={isSavingConfig}
          ></Button>
        </Tooltip>
      </div>
    </div>
  );
};
