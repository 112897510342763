/* eslint-disable react-refresh/only-export-components */
import { Tag } from "antd";
import _ from "lodash";
import { iPricingPlan } from "./pricing-plan-model";

const CheckMark = <i className="ri-check-line"></i>;
const Users = <i className="ri-group-line"></i>;
const Support = <i className="ri-customer-service-line"></i>;
const AccountManager = <i className="ri-account-pin-box-line"></i>;
const NotIncluded = <i className="ri-close-line"></i>;
const powerCord = <i className="ri-plug-line"></i>;
const power = <i className="ri-flashlight-fill"></i>;
const warning = <i className="ri-error-warning-fill"></i>;
const magicWand = <i className="ri-magic-line"></i>;

export const TEAM_PRICING: iPricingPlan[] = [
  {
    id: "TEAM",
    name: "Team",
    description: "For Small Teams",
    price: "$99",
    limits: {
      users: 3,
      connections: 3,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in 'Startup' plan"
      // },
      // {
      //   icon: powerCord,
      //   content: "Omni Channel Conversations"
      // },
      // {
      //   icon: powerCord,
      //   content: "Facebook | Instagram | Whatsapp"
      // },
      // {
      //   icon: power,
      //   content: "Basic Reports"
      // },
      {
        icon: warning,
        content: "3 Channels",
      },
      {
        icon: warning,
        content: "3 users",
      },
    ],
  },
  {
    id: "TEAM_PLUS",
    name: "Team Plus",
    description: "For Growing Teams",
    price: "$249",
    limits: {
      users: 10,
      connections: 5,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in 'Team' plan"
      // },

      // {
      //   icon: magicWand,
      //   content: "Zapier Integration"
      // },
      // {
      //   icon: magicWand,
      //   content: "Workflow Automation",
      // },
      {
        icon: warning,
        content: "6 Channels",
      },
      {
        icon: warning,
        content: "6 Users",
      },
    ],
  },
];

export const APPSUMO_PLANS: iPricingPlan[] = [
  {
    id: "APP_SUMO_LICENSE",
    name: "Appsumo License",
    description: "LTD Purchased from AppSumo",
    price: "$0",
    limits: {
      users: 3,
      connections: 2,
    },
    listItems: [],
  },
];

export const STARTUP_PRICING: iPricingPlan[] = [
  {
    id: "STARTUP",
    name: "Startup",
    description: "For startups that are sprouting",
    price: "Free",
    limits: {
      users: 3,
      connections: 2,
    },
    listItems: [
      // {
      //   icon: powerCord,
      //   content: "Live Chat"
      // },
      // {
      //   icon: powerCord,
      //   content: "Email Help Desk"
      // },
      // {
      //   icon: power,
      //   content: "CRM"
      // },
      // {
      //   icon: power,
      //   content: "Auto triggered conversation starters"
      // },
      // {
      //   icon: power,
      //   content: "Lead Collection"
      // },
      {
        icon: warning,
        content: "3 users",
      },
      {
        icon: warning,
        content: "2 channels",
      },
      {
        icon: warning,
        content: "50 Conversations/months",
      },
    ],
  },
  // {
  //   id: "STARTUP_PLUS",
  //   name: "Startup Plus",
  //   description: "A little extra",
  //   price: "$49",
  //   limits: {
  //     users: 3,
  //     connections: 2,
  //   },
  //   listItems: [
  //     // {
  //     //   icon: CheckMark,
  //     //   content: "Everything in 'Startup' plan"
  //     // },
  //     // {
  //     //   icon: magicWand,
  //     //   content: "Zapier Integration"
  //     // },
  //     // {
  //     //   icon: magicWand,
  //     //   content: "Workflow Automation"
  //     // },
  //     // {
  //     //   icon: power,
  //     //   content: "Mobile App"
  //     // },
  //     // {
  //     //   icon: CheckMark,
  //     //   content: "Unlimited Conversation"
  //     // },

  //     {
  //       icon: warning,
  //       content: "3 users",
  //     },
  //     {
  //       icon: warning,
  //       content: "2 Channels",
  //     },
  //   ],
  // },
];

export const BUSINESS_PRICING: iPricingPlan[] = [
  {
    id: "BUSINESS",
    name: "Business",
    description: "For Growing Businesses",
    price: "$499",
    limits: {
      users: 15,
      connections: 10,
    },
    isRecommended: true,
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in Team"
      // },
      {
        icon: CheckMark,
        content: "10 Channels",
      },
      {
        icon: CheckMark,
        content: "15 users",
      },
    ],
  },
  {
    id: "BUSINESS_PLUS",
    name: "Business Plus",
    description: "For Large Businesses",
    price: "$799",
    limits: {
      users: 25,
      connections: 10,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in Business plan"
      // },
      // {
      //   icon: magicWand,
      //   content: "Workflow Automation",
      // },
      {
        icon: CheckMark,
        content: "10 Channels",
      },
      {
        icon: CheckMark,
        content: "25 users",
      },
      // {
      //   icon: magicWand,
      //   content: "Zapier Integration"
      // },
      // {
      //   icon: magicWand,
      //   content: "Workflow Automation"
      // }
    ],
  },
  {
    id: "ENTERPRISE",
    name: "Enterprise",
    description: "For large teams and enterprises \n -",
    price: "Talk to us",
    limits: {
      users: 50,
      connections: 20,
    },
    listItems: [
      // {
      //   icon: CheckMark,
      //   content: "Everything in Business+ plan"
      // },
      {
        icon: CheckMark,
        content: "50 Users",
      },
      {
        icon: CheckMark,
        content: "User Training",
      },
      {
        icon: CheckMark,
        content: "SSO",
      },
      {
        icon: magicWand,
        content: "Custom Integrations",
      },
    ],
  },
];

export const GetPricingPlanById = (planId: string) => {
  const id = planId.replace(`_YEARLY`, "").replace(`_MONTHLY`, "");
  const plan = _.find(
    [...NewPlans],
    // [...STARTUP_PRICING, ...TEAM_PRICING, ...BUSINESS_PRICING],
    {
      id,
    },
  );
  if (plan) {
    return {
      ...plan,
      planId,
    };
  }
  return undefined;
};

export interface iAddOnOption {
  addOnId: string;
  title: string;
  pricing: {
    monthly: string;
    yearly: string;
  };
  description: string | JSX.Element;
}

export const AddOnLabels = {
  WHATSAPP: "Whatsapp Instance",
  MOBILE_NUMBER: "Mobile Number",
};

export const AvailableAddons: iAddOnOption[] = [
  // {
  //   addOnId: "WHATSAPP",
  //   title: "WhatApp Instance",
  //   pricing: {
  //     monthly: "$49",
  //     yearly: "$41",
  //   },
  //   description: (
  //     <>
  //       {/* Connect Either Whatsapp Business Account or Private Whatsapp Integration
  //       to send and receive WA messages via ClickConnector. */}
  //       <br />
  //       <div className="mt-2 mb-2">You don't need this anymore</div>
  //       {/* <div className="mt-2 mb-2">* Phone Number Not Included</div> */}
  //     </>
  //   ),
  // },
  {
    addOnId: "MOBILE_NUMBER",
    title: "Mobile Number",
    pricing: {
      monthly: "$15",
      yearly: "$13",
    },
    description: (
      <>
        You can buy a number of your choice and you can use it to send SMS and
        make phone calls.
        <br /> <Tag>20 Minutes/Month</Tag>
        <Tag> 100 SMS/Month</Tag>
        <br />
        <div className="mt-2">* Speak to Sales for Additional Quota</div>
      </>
    ),
  },
];

const _old_plans = [
  {
    id: "V3_BASIC",
    category: "Startup",
    name: "Basic",
    price: "Free",
    description: "For very early stage startups",
    lineItems: ["2 Users", "1 Channel", "2 months history"],
    limits: {
      users: 2,
      connections: 1,
    },
  },
  {
    id: "V3_PRO",
    category: "Startup",
    name: "Pro",
    price: 19,
    description: "For early stage startups",
    lineItems: [
      "2 Users",
      "3 Channels",
      "Unlimited History",
      "More Product Tours",
      "More Sequences",
      "Unlimited Views",
      "More Email Campaigns",
      "AI Skills",
    ],
    limits: {
      users: 2,
      connections: 3,
    },
  },
  {
    id: "V3_STARTUP_PRO",
    category: "Startup",
    name: "Startup Pro",
    price: 49,
    description: "I need a customer support tool",
    lineItems: [
      "4 Users",
      "3 Channels",
      "Unlimited History",
      "2 Product Tours",
      "1 Email Sequence",
    ],
    limits: {
      users: 2,
      connections: 3,
    },
  },
  {
    id: "V3_STARTUP_PREMIUM",
    category: "Startup",
    name: "Startup Premium",
    price: 65,
    description: "Our customer support tool must be top-notch",
    lineItems: [
      "4 Users",
      "6 Channels",
      "More Product Tours",
      "More Sequences",
      "Unlimited Views",
      "More Email Campaigns",
      "AI Skills",
    ],
    limits: {
      users: 3,
      connections: 6,
    },
  },
  {
    id: "V3_TEAM",
    category: "Team",
    name: "Team",
    price: 49,
    description: "I have a small customer support team with little support",
    lineItems: ["3 users", "3 Channels"],
    limits: {
      users: 3,
      connections: 3,
    },
  },
  {
    id: "V3_TEAM_PLUS",
    category: "Team",
    name: "Team+",
    price: 65,
    description: "My customer support operations is growing",
    lineItems: ["6 Users", "6 Channels", "2 Product Tours", "1 Email Sequence"],
    limits: {
      users: 6,
      connections: 6,
    },
  },
  {
    id: "V3_TEAM_PREMIUM",
    category: "Team",
    name: "Team Premium",
    price: 99,
    description: "I have a customer support team now",
    lineItems: [
      "6 Users",
      "10 Channels",
      "More Product Tours",
      "More Sequences",
      "More Email Campaigns",
      "AI Skills",
    ],
    limits: {
      users: 6,
      connections: 10,
    },
  },
  {
    id: "V3_BUSINESS_PLUS",
    category: "Business",
    name: "Business+",
    price: 249,
    description: "I have a customer support team now",
    lineItems: [
      "10 Users",
      "10 Channels",
      "Full Proactive Support Kit",
      "More Automations",
      "AI Skills",
    ],
    limits: {
      users: 10,
      connections: 10,
    },
  },
  {
    id: "V3_BUSINESS_PREMIUM",
    category: "Business",
    name: "Business Premium",
    price: 499,
    description: "My support operations is sophisticated",
    lineItems: [
      "16 Users",
      "12 Channels",
      "Full Proactive Support Kit",
      "More Automations",
      "Deeper Reports and Analytics",
      "AI Skills",
    ],
    limits: {
      users: 16,
      connections: 12,
    },
  },
  {
    id: "V3_ENTERPRISE",
    category: "Business",
    name: "Enterprise",
    price: "Talk to us",
    description: "We need more",
    lineItems: [
      "SSO",
      "Advanced Analytics",
      "Custom Integrations",
      "2 Factor Authentication",
    ],
    limits: {
      users: 20,
      connections: 12,
    },
  },
];

const plans = [
  {
    id: "V3_BASIC",
    category: "Startup",
    name: "Seed",
    price: "Free",
    description: "For very early stage startups",
    lineItems: [
      "2 Users Included",
      "1 Channel (Live Chat)",
      "Upto 2,000 contacts",
      "Upto 200 AI Assists",
      "Everything is included - Magic Assistant, HelpDesk, HelpDesk, Trackers, KnowledgeBase Portals and Engage Kit",
    ],
    limits: {
      users: 2,
      connections: 1,
    },
  },
  {
    id: "V3_STARTUP_PRO",
    category: "Startup",
    name: "Startup",
    price: 49,
    description: "Perfect for startups",
    lineItems: [
      "4 Users Included",
      "Integrate Multiple Channels",
      "Upto 10,000 users/contacts",
      "Unlimited AI Assists",
      "Everything is included - Magic Assistant, HelpDesk, HelpDesk, Trackers, KnowledgeBase Portals and Engage Kit",
    ],
    limits: {
      users: 4,
      connections: 3,
    },
  },
  {
    id: "V3_TEAM_PREMIUM",
    category: "Team",
    name: "Pro",
    price: 99,
    description: "Recommended for growing startups",
    lineItems: [
      "6 Users",
      "Integrate Multiple Channels",
      "Upto 20,000 contacts",
      "Unlimited AI Assists",
      "Everything is included - Magic Assistant, HelpDesk, HelpDesk, Trackers, KnowledgeBase Portals and Engage Kit",
    ],
    limits: {
      users: 6,
      connections: 10,
    },
  },
  {
    id: "V3_BUSINESS_PLUS",
    category: "Business",
    name: "Business+",
    price: 249,
    description: "I have a customer support team now",
    lineItems: [
      "10 Users",
      "Integrate Multiple Channels",
      "Upto 40,000 contacts",
      "Unlimited AI Assists",
      "Everything is included - Magic Assistant, HelpDesk, HelpDesk, Trackers, KnowledgeBase Portals and Engage Kit",
    ],
    limits: {
      users: 10,
      connections: 10,
    },
  },
  {
    id: "V3_BUSINESS_PREMIUM",
    category: "Business",
    name: "Business Premium",
    price: 499,
    description: "My support operations is sophisticated",
    lineItems: [
      "16 Users",
      "Integrate Multiple Channels",
      "Upto 100,000 contacts",
      "Unlimited AI Assists",
      "Everything is included - Magic Assistant, HelpDesk, HelpDesk, Trackers, KnowledgeBase Portals and Engage Kit",
    ],
    limits: {
      users: 16,
      connections: 12,
    },
  },
  {
    id: "V3_ENTERPRISE",
    category: "Business",
    name: "Enterprise",
    price: "Talk to us",
    description: "We need more",
    lineItems: [
      "SSO",
      "Advanced Analytics",
      "Custom Integrations",
      "2 Factor Authentication",
    ],
    limits: {
      users: 20,
      connections: 12,
    },
  },
];

export const NewPlans = plans.map(
  (item) =>
    ({
      id: item.id,
      name: item.name,
      category: item.category,
      description: item.description,
      price: typeof item.price === "number" ? `$${item.price}` : item.price,
      listItems: item.lineItems.map((item) => ({
        icon: CheckMark,
        content: item,
      })),
      limits: item.limits,
    }) as iPricingPlan,
);
