import { GenerateModalWithSuspense } from "@libs/modal-panels/modal-registry-helpers";
import { iModalProps } from "@libs/modal-panels/modal-registry-model";
import React from "react";

const ClickUpListSelectorModal = React.lazy(
  () => import("./click-up-list-selector.modal"),
);

const ClickUpListSelector: iModalProps<
  typeof ClickUpListSelectorModal,
  { initialValues: any; onSelected: (values: any) => any }
> = {
  name: "click-up-list-selector",
  component: GenerateModalWithSuspense(ClickUpListSelectorModal),
};

export default ClickUpListSelector;
